import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

const slider = '.js-area-carousel';

const areaCarousel = new Swiper(slider, {
    modules: [Navigation],
    navigation: {
        prevEl: '.swiper-prev-products',
        nextEl: '.swiper-next-products',
    },
    breakpoints: {
        1200: {
            slidesPerView: 4,
            spaceBetween: 32,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 16,
        },
        400: {
            slidesPerView: 1.5,
            spaceBetween: 16,
        },
        360: {
            slidesPerView: 1.2,
            spaceBetween: 16,
        },
        300: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
    },
});
